<template>
 <div>
    <div>
      <!-- Contenu du mélange de produits -->
      <h4 class="subtitle-form">{{ $t("inputStock.details.products_mix_content")}}</h4>
      <b-row class="m-0" v-for="productAlias in productsMixContent" v-bind:key="productAlias.refProduct">
        <b-col
          cols="7"
          class="text-infos-without-bg mt-1"
          
        >{{ productAlias.type + " - " + productAlias.refProduct_name }}</b-col>
        <b-col cols="3" class="text-infos-without-bg">
          <b-input
            size="sm"
            ref="focused"
            class="form-control-fx mr-2"
            step="0.01"
            min=0
            max=100
            :placeholder=" (100 - totalPercent).toString()"
            v-model="productAlias.percentInComposition"
            type="number"
            required
          ></b-input>
        </b-col>
        <b-col cols="1" class="text-infos-without-bg mt-1">
          %
        </b-col>
        <b-col cols="1" class="remove-btn p-0">
          <button class="btn align-bottom" @click="onDelete(productAlias)" type="button">
            <img :src="icon" alt="icon delete action" />
          </button>
        </b-col>
      </b-row>
      <br/>
    </div> 
    <div>
      <!-- Ajout d'un produit au mélange -->
      <h4 class="subtitle-form">{{ $t("inputStock.details.add_product")}}</h4>
      <b-row>
        <!-- Sélecteur du type de produit -->
        <b-col cols="7" class="pr-1">
          <Dropdown
            ref="dropdown"
            class="shape-custom-select"
            :options="productTypesByFamily"
            label="designation"
            :value="currentProductType"
            @input="type => onProductTypeChoice(type)"
            required
          />
        </b-col>
        <!-- Sélecteur du produit -->
        <b-col cols="5" class="pl-0">
          <Dropdown
            ref="dropdown2"
            class="shape-custom-select"
            :options="rows"
            label="name"
            :value="selectedProduct"
            required
            @input="product => onInput(product)"
            :clearSelection="true"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from "@/components/inputstock/details/Dropdown.vue";
import { CLEAN_RESEARCH_RESULTS } from "@/store/modules/productsAliases";
import { mapActions, mapGetters } from "vuex";
import delIcon from "@/assets/images/delete.svg";
import { EventBus } from "@/eventBus.js";
import { i18n } from "@/i18n.js";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      currentProductType: {},
      selectedProduct: {},
      icon: delIcon,
      productsMixContent: [],
      productsMixContentClone: [],
      totalPercent: 0,
    };
  },
  components: {
    Dropdown,
  },
  async created() {
    this.loadOn();
    EventBus.$on("onSave", this.autoCompletionPercents);

    this.clearProductTypeData();
    this.currentProductType = this.getCurrentProductType();

    await Promise.all([this.getProductFamilies(), this.getProductTypesByFamily({ idProdFamily: this.familyCode })]);

    if (this.$route.name == "inputStock.edit") {
      this.productsMixContent = [];
      this.currentEntry.mixedInput_composition.map(async element => {
        let productAlias = await this.getProduct(element.refProduct_name);
        this.productsMixContent.push({
          refProduct: element.refProduct,
          refProduct_name: element.refProduct_name,
          percentInComposition: element.percentInComposition,
          product: productAlias.product,
          type: productAlias.productType,
        });
      });
    }

    if (this.currentProductType) {
      this.loadProductTypeData();
    }

    this.loadOff();
  },
  computed: {
    ...mapGetters({
      rows: "researchResults",
      productFamilies: "productFamilies",
      productTypesByFamily: "productTypesByFamily",
    }),

    familyCode() {
      return this.currentEditedEntry.productFamily_code || this.currentEntry.productFamily_code;
    },

    currentProductFamily() {
      return this.productFamilies.find(
        p => p.code == (this.currentEntry.productFamily_code || this.currentEditedEntry.productFamily_code),
      );
    },
  },
  methods: {
    ...mapActions(["getProductFamilies", "getProductTypesByFamily"]),

    /**
     * @description stocke le type de produit sélectionné, charge ou vide le sélecteur des produits
     * @param {object} type le type de produit sélectionné
     */
    async onProductTypeChoice(type) {
      if (type) {
        this.currentProductType = type;
        this.loadProductTypeData();
      }
      else {
        this.currentProductType = {};
      }
    },

    /**
     * @description récupère le type de produit principal du mélange
     */
    getCurrentProductType() {
      let ptype = this.currentEntry.productType;
      if ("productType" in this.currentEditedEntry) ptype = this.currentEditedEntry.productType;
      return this.productTypesByFamily.find(p => p.id == ptype);
    },

    /**
     * @description permet de charger, dans rows, la liste des produits dont le type à été sélectionné
     */
    async loadProductAliases({ name }) {
      if (name.length >= 3)
        await this.$store.dispatch("researchProductsAliases", {
          name: name,
          idProductType: this.currentProductType && this.currentProductType.id,
          idProductFamily: this.currentProductFamily && this.currentProductFamily.id,
        });
      else if (this.currentProductType)
        await this.$store.dispatch("getProductsAliases", { id: this.currentProductType.id });
    },

    /**
     * @description Permet de mettre la liste des produits à jour
     */
    async loadProductTypeData({ name } = { name: "" }) {
      this.clearProductTypeData();
      await this.loadProductAliases({ name });
    },

    /**
     * @description Permet de vider la liste des produits
     */
    clearProductTypeData() {
      this.$store.commit(CLEAN_RESEARCH_RESULTS);
    },

    /**
     * @description Permet de vérifier si un produit a déja été ajouté au mélange
     */
    isAlreadyAdded(productAlias) {
      let count = 0;
      this.productsMixContent.map(p => {
        if(p.product === productAlias.product) {
          count ++;
        }
      });
      if(count === 0)
        return false;
      else
        return true;
    },

    /**
     * @description ajoute un produit au tableau des produits du mélange
     * @param {object} productAlias le produit sélectionné
     */
    onInput(productAlias) {
      if(productAlias) {
        if(!this.isAlreadyAdded(productAlias))
          this.productsMixContent.push({
            refProduct: productAlias.id,
            refProduct_name: productAlias.name,
            product: productAlias.product,
            type: this.currentProductType.designation,
            percentInComposition: null
          });
      }
      this.selectedProduct = {};
    },

    /**
     * @description retire un produit du tableau des produits du mélange
     * @param {object} productAlias le produit à supprimer
     */
    onDelete(productAlias) {
      const filter = this.productsMixContent.indexOf(productAlias);
      if (filter > -1) this.productsMixContent.splice(filter, 1);
    },

    /**
     * @description permet d'obtenir un produit à partir de son nom dans le catalogue des produits de référence
     * @param {string} refProduct_name nom du produit de référence
     * @returns {object} produit
     */
    async getProduct(refProduct_name) {
      let productAlias = {};
      productAlias = await this.$store.dispatch("researchProductsAliases", { name: refProduct_name });
      return productAlias[0];
    },

    /**
     * @description lors de l'enregistrement d'un mélange, les pourcentages de produits non renseignés prennent
     * la valeur du complément à 100 du total des pourcentages
     */
    autoCompletionPercents() {
      this.productsMixContent.map(productAlias => {
        if(!productAlias.percentInComposition) {
          productAlias.percentInComposition = (100 - this.totalPercent) > 0 ? (100 - this.totalPercent) : 0
        }
      });
    }
    
  },

  watch: {
    productsMixContent: {
      deep: true,
      handler: function () {
        this.productsMixContentClone = JSON.parse(JSON.stringify(this.productsMixContent));
        this.totalPercent = 0;
        this.productsMixContentClone.map(p => {
          if(p.percentInComposition) {
            p.percentInComposition = parseFloat(p.percentInComposition);
            this.totalPercent = this.totalPercent + p.percentInComposition;
          }
        });
        for(let i=0; i < this.productsMixContent.length; i++) {
          if(this.$refs.focused && this.$refs.focused[i])
            this.$refs.focused[i].setCustomValidity("");
          if(this.totalPercent != 100)
          {
            if(this.$refs.focused && this.$refs.focused[i]) {
              if (i === this.productsMixContent.length - 1)
                this.$refs.focused[i].setCustomValidity(i18n.t("inputStock.details.control_total_percent"));
            }
          }
        }
        this.$store.dispatch(this.storeEditAction, { mixedInput_composition: this.productsMixContentClone });
      },
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  //   background-color: $danger-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}


/**
 * Parameter error percent.
 */
.error-percent {
  // text-align: center;
  color: $danger-color;
}
</style>